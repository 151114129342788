import React from 'react';
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import Paragraph1 from '../components/Type/Paragraph1'
import Heading2 from '../components/Type/Heading2'
import Grid2 from '../components/Layout/Grid2/Grid2'
import GridItem from '../components/Layout/GridItem/GridItem'

//import { Link } from "gatsby"

const ppsrPage = ({location}) => (

  <Layout>

    <SEO title="Summon COVID Safe Measures"
      description="The health and wellbeing of our customers, car buyers and concierges are important to us and
      to help you during this time, we have compiled some procedures on how we ensure to stay safe
      and practice social distancing, while your car changes hands."
      location={location}
    />

    <PageHeader
      title="COVID Safe Measures"
      text=""
    />

    <SectionPageMargin marginBottom="0px">
      <div style={{ width: '100%' }}>
        <Heading2>For Sellers</Heading2>
      </div>
    </SectionPageMargin>

    <SectionPageMargin >
      <Grid2>
        <GridItem>
          <Paragraph1>
            <strong>Safe selling during COVID-19</strong><br />
                The health and wellbeing of our customers, car buyers and concierges are important to us and
                to help you during this time, we have compiled some procedures on how we ensure to stay safe
                and practice social distancing, while your car changes hands.
              </Paragraph1>
        </GridItem>
        <GridItem>
          <Paragraph1>
            <strong>1. Wash your hands</strong><br />
                Before meeting our concierge team, please wash your hands for 20 seconds with soap or use an
                alcohol-based sanitizer.
              </Paragraph1>

          <Paragraph1>
            <strong>2. Wear a face mask</strong><br />
                For the protection of yourself and our team, our concierge will wear a mask, and we ask you to please do the same.
              </Paragraph1>

          <Paragraph1>
            <strong>3. Social distancing</strong><br />
                Please maintain a 1.5m distance from others.
              </Paragraph1>

          <Paragraph1>
            <strong>4. Feeling unwell?</strong><br />
                If you or someone in your household is feeling unwell, do not arrange any meetings with our concierge until you have fully recovered.
              </Paragraph1>
        </GridItem>
      </Grid2>
    </SectionPageMargin>


    <SectionPageMargin marginBottom="0px"  bgColor="#f8f8f9">
      <div style={{ width: '100%' }}>
        <Heading2>For buyers</Heading2>
      </div>
    </SectionPageMargin>

    <SectionPageMargin marginBottom="0px"  bgColor="#f8f8f9">
      <Grid2>
        <GridItem>
          <Paragraph1>
            <strong>Am I safe test driving a car?</strong><br />
                It’s important to take proper precautions before test driving a car. Here are the precautions we at Summon
                have taken to ensure the car is safe and clean;
              </Paragraph1>
        </GridItem>
        <GridItem>
          <Paragraph1>
            <strong>1. Car cleaning</strong><br />
            We will have cleaned the car and disinfected high touch areas before you test-drive.
          </Paragraph1>
          <Paragraph1>
            <strong>2. Wear a face mask</strong><br />
            For the protection of yourself and our team, our concierge will wear a mask, and we ask you to please do the same.
          </Paragraph1>
          <Paragraph1>
            <strong>3. Social distancing</strong><br />
            Please maintain a 1.5m distance from others.
          </Paragraph1>
        </GridItem>
      </Grid2>
    </SectionPageMargin>

    <SectionPageMargin bgColor="#f8f8f9">
      <Grid2 style={{paddingTop: '30px', paddingBottom: '50px'}}>
        <GridItem>
        <Paragraph1>
            Here are some additional measures you can take to <span style={{whiteSpace: "nowrap"}}>minimise the risk;</span>
          </Paragraph1>
        </GridItem>
        <GridItem>
          <Paragraph1>
            <strong>1. Remote inspections</strong><br />
            Think about an initial inspection or walk-through via video instead of a physical meet-up.
          </Paragraph1>
          <Paragraph1>
            <strong>2. Sit in the back</strong><br />
            If you prefer, ask the Summon concierge to sit in the back to observe the social distancing recommendations.
          </Paragraph1>
          <Paragraph1>
            <strong>3. Feeling unwell?</strong><br />
            Act responsibly. If you or someone in your household is feeling unwell, do not arrange an in-person
            inspection or test-drive – stay at home until you have fully recovered.
          </Paragraph1>
        </GridItem>
      </Grid2>
    </SectionPageMargin>

  </Layout>

)

export default ppsrPage

